import React from 'react';

import Slider from 'react-slick';
import './New.scss';
import imgNew from '/src/static/images/image-new.jpg';
import {Link} from 'gatsby';

const settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: true,
  dots: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },

    {
      breakpoint: 601,
      settings: {
        className: 'center',
        centerMode: true,
        // centerPadding: '44px',
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const NewEn = ({data}) => {
  const posts = data.nodes;
  if(posts.length === 1) settings.slidesToShow = 1;
  return (
    <section id="home-new">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h2 className="text-center">NEWS</h2>

            <div className="slider__content box-news">
            <Slider {...settings}>
                  {posts.map((v, index) => (
                    <div className="card-new" key={index}>
                      <div className="information">
                        <div className="information-content">
                          <h3 className="title">{v.title}</h3>

                          <p className="description"
                             dangerouslySetInnerHTML={{ __html: v.excerpt}}>
                          </p>
                          <Link to={`/en/news/${v.slug}`} className="btn btn--primary auto">
                            Read more
                          </Link>
                        </div>
                      </div>

                      <picture>
                        {v.featuredImage ?
                          (<>
                            <img src={v.featuredImage.node.sourceUrl} alt={`${v.title} Thumbnail`} />
                          </>) :
                          (<>
                            <img src={imgNew} alt={`${v.title} Thumbnail`} />
                          </>)
                        }
                      </picture>
                    </div>
                  ))}
                </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewEn;
