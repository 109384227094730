import React, {useState} from 'react';

import './Latest.scss';

const Latest = ({thanksModal}) => {
  const [ formState, setFormState ] = useState({
    name: "",
    email: "",
    suscribed: true,
    data: {
      action: 'newsletter-form'
    }
  });
  
  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let state = formState;
    state[name] = value;
    setFormState(state);
  }
  const handleSubmit = event => {
    thanksModal();
    event.preventDefault();
    let requestOptions = {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(formState),
      redirect: 'follow'
    };
    fetch(`${process.env.GATSBY_WORDPRESS_URL}/wp-json/whiz-api/v1/lead`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  return (
    <section id="home-latest">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2 className="text-center">NEWSLETTER</h2>

            <form action="" onSubmit={handleSubmit}>
              <div className="group">
                <input
                  onChange={handleInputChange}
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Type your Name"
                  required
                />
                <input
                  onChange={handleInputChange}
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Type your Email"
                  required
                />
              </div>
              <button className="btn btn--primary" type="submit">subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Latest;