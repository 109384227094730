import React from 'react';
import './Instagram.scss';
import Slider from "react-slick";
const InstagramEn = ({data}) => {

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 601,
        settings: {
          className: 'center',
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
    ]
  };

  return (
    <section id="home-instagram">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
          <h2 className="text-center">{data.title}</h2><br />
            <div className="tabs-container">

              <div className="tabs active" tab-id="1">
                <div className="slider__content instagram-slider">
                  <Slider {...settings}>
                    {data.entesdmjc ? data.entesdmjc.map((img, key) => {
                      return (
                        <a href="https://www.instagram.com/entesdmjc/" key={key}>
                          <img src={img.link} alt="Instagram image"/>
                        </a>
                      )
                    }) : ''}
                  </Slider>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstagramEn;
