import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Portal from './portal';

import * as style from './_layout.module.scss';

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => show(),
      closeModal: () => close(),
    };
  });

  const show = () => setDisplay(true);
  const close = () => setDisplay(false);

  useEffect(() => {
    const closeModal = (e) => {
      if (e.keyCode === 27) {
        close();
      }
    };
    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal);
  }, []);

  const content = (
    <div
      className={style.modal}
      role="dialog"
      aria-modal="true"
      aria-labelledby=""
      onClick={close}
      aria-hidden="true"
    >
      <div
        className={`${style.modal_dialog} ${props.modalClass}`}
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <button
          className={style.modal_close}
          type="button"
          aria-label="Cerrar modal"
          onClick={close}
        ></button>

        <div className={style.modal_container}>{props.children}</div>
      </div>
    </div>
  );

  // if (display) {
  //   return ReactDOM.createPortal(
  //     content,
  //     document.getElementById('root-modal')
  //   );
  // }

  if (display) {
    return <Portal>{content}</Portal>;
  }

  return null;
});

export default Modal;
