import React from 'react';
//styles
import './Banner.scss';
//components
import { Link } from "gatsby";
import  Slider  from "react-slick";
const Banner = ({data}) => {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    autoplay: true,
    arrows: true,
    dots: true, 
  };
  return (
    <>
    <div id="home-banner">
      <div className="top">
        <button className="sound hide" aria-label="sound"> </button>
        <div className="language">
          <Link to="/">
            <button className="separator" >ES</button>
          </Link>
          <button className="active">EN</button>
        </div>
      </div>
      <div className='slider__content'>
        <Slider {...settings}>
          { data.bannerImages.map( (el, key) => (
            <div className='' key={key}>
              <div className='img_container' style={{backgroundImage:`url(${el.sourceUrl})`}}>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
    </>
  );
};
export default Banner;